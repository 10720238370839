import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Attachment } from 'app/_models/attachment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: "app-attachment-list",
  templateUrl: "./attachment-list.component.html",
  styleUrls: ["./attachment-list.component.scss"]
})
export class AttachmentListComponent implements OnInit {
  attachmentsDatasource = new BehaviorSubject<AbstractControl[]>([]);
  attachmentsDisplayedColumns: string[] = [
    "add",
    "file",
    "type",
    "description"
  ];

  @Input()
  form: FormGroup;
  @Input()
  types: string[];
  @Input()
  showExpiration: boolean = false;

  private _attachments: Attachment[];

  constructor(private fb: FormBuilder) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showExpiration']) {
      if (this.showExpiration) {
        this.attachmentsDisplayedColumns = [
          "add",
          "file",
          "type",
          "description",
          "expiration"
        ];
      } else {
        this.attachmentsDisplayedColumns = [
          "add",
          "file",
          "type",
          "description"
        ];
      }
    }
  }

  addAttachment(attachment: Attachment) {
    let group = {
      objectId: [attachment ? attachment.objectId : null],
      file: ["", Validators.required],
      type: [""],
      description: [""],
      expiration: [""]
    };

    const attachmentCtrl = this.fb.group(group);

    if (attachment) {
      attachmentCtrl.patchValue({
        objectId: attachment.objectId,
        file: attachment.url || attachment.file,
        type: attachment.type,
        description: attachment.description,
        expiration: attachment.expiration
      });
    }

    this.attachmentsForm.push(attachmentCtrl);
    this.attachmentsDatasource.next(this.attachmentsForm.controls);
  }

  private resetAttachmentsForm() {
    while (this.attachmentsForm.length !== 0) {
      this.attachmentsForm.removeAt(0);
    }
    this.attachmentsDatasource.next(this.attachmentsForm.controls);
  }

  removeAttachment(index) {
    this.attachmentsForm.removeAt(index);
    this.form.markAsDirty();
    this.attachmentsDatasource.next(this.attachmentsForm.controls);
  }

  get attachmentsForm(): FormArray {
    return this.form.get("attachments") as FormArray;
  }

  get attachments(): Attachment[] {
    return this._attachments;
  }

  @Input()
  set attachments(attachments: Attachment[]) {
    this._attachments = attachments;
    if (attachments) {
      attachments.forEach(attachment => this.addAttachment(attachment));
    }
  }
}
