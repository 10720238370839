import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Attachment } from 'app/_models/attachment';
import { AlertService } from 'app/_services/alert.service';
import { OrgService } from 'app/_services/org.service';

@Component({
  selector: "attachment-edit",
  templateUrl: "./attachment-edit.component.html",
  styleUrls: ["./attachment-edit.component.scss"]
})
export class AttachmentEditComponent {

  attachmentForm: FormGroup = this.fb.group({
    file: ["", Validators.required],
    type: [""],
    description: [""],
    expiration: [""]
  });

  attachment: Attachment;

  constructor(private fb: FormBuilder, private orgService: OrgService, public dialog: MatDialog,
    public dialogRef: MatDialogRef<AttachmentEditComponent>,
    private alertService: AlertService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.attachment = this.data.attachment;
      this.initForm();
    }
  }

  private initForm() {
    this.attachmentForm.reset();
    if (this.attachment) {
      this.attachmentForm.patchValue({
        file: this.attachment.url || this.attachment.file,
        type: this.attachment.type,
        description: this.attachment.description,
        expiration: this.attachment.expiration
      })
    }
  }

  saveAttachment() {
    const savingEntity = Attachment.fromFormGroup(this.attachmentForm, this.attachment);
    savingEntity.folderId = this.data.folder.id;
    if (savingEntity) {
      this.orgService.storeOrgAttachment(savingEntity, this.data.orgId).subscribe((result) => {
        this.attachment = result;
        this.dialogRef.close(this.attachment);
      })
    }
  }

  deleteAttachment() {
    if (this.attachment) {
      this.alertService.showConfirmDialog("Elimina", `Sei sicuro di voler eliminare il documento ${this.attachment.filename}?`).subscribe(confirm => {
        if (confirm) {
          this.orgService.deleteAttachment(this.attachment).subscribe(() => {
            this.dialogRef.close({ deleted: true, id: this.attachment.objectId });
          })
        }
      })
    }
  }

  close() {
    if (this.attachmentForm.pristine) {
      this.dialogRef.close();
    } else {
      this.alertService.showConfirmDialog("Chiudi", "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?").subscribe(confirm => {
        if (confirm) {
          this.dialogRef.close();
        }
      });
    }
  }
}
