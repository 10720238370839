import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { PapaParseService } from 'ngx-papaparse';

import { Org } from '../../../_models/org';
import { OrgService } from '../../../_services/org.service';

@Component({
  selector: "app-import-orgs-dialog",
  templateUrl: "./import-orgs-dialog.component.html",
  styleUrls: ["./import-orgs-dialog.component.scss"]
})
export class ImportOrgsDialogComponent implements OnInit {
  file: File;
  hasUpdates: boolean = false;
  importing: boolean = false;
  importCompleted: boolean = false;
  errors;

  constructor(
    private papa: PapaParseService,
    private orgService: OrgService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ImportOrgsDialogComponent>,
  ) { }

  ngOnInit() { }

  fileChanged(event) {
    this.file = event.target.files[0];
  }

  startImport() {

    if (this.file) {
      var reader = new FileReader();
      reader.onload = (evt: any) => {
        let orgsData = JSON.parse(evt.target.result);
        console.log(orgsData);
        let orgs = orgsData.map(orgData => {
          let org = new Org(null);
          org.objectId = orgData["ID"];
          org.name = orgData["NAME"];
          org.VAT = "" + orgData["PIVA"];
          org.address = {
            ZIP: orgData["CAP"],
            street: orgData["ADDRESS"],
            city: orgData["CITY"],
            province: orgData["PROV"]
          };
          org.firstContact = {
            name: orgData["REF NAME"],
            surname: orgData["REF SURNAME"],
            email: orgData["REF MAIL"]
          }
          org.warningDays = null;
          return org;
        })
        this.orgService
          .importOrgs(orgs)
          .then(() => {
            console.log("Import completed");
            this.importing = false;
            this.hasUpdates = true;
            this.importCompleted = true;
            this.file = null;
          })
          .catch(error => {
            this.importing = false;
            this.hasUpdates = true;
            this.errors = error.message;
            this.importCompleted = true;
            console.error("Errors while importing", error);
          });
      };
      reader.onerror = function (evt) {
        console.log('error reading file', evt);
      }
      reader.readAsText(this.file, "UTF-8");
    }
  }

  close() {
    this.dialogRef.close(this.hasUpdates);
  }
}
