import { AbstractControl } from '@angular/forms';
import { formatDateForBackend, sortArray } from 'app/_helpers/utils';

import { environment } from './../../environments/environment';
import { CommonObject, CommonObjectDTO } from './common-object';
import { Employee, EmployeeDTO } from './employee';
import { Org, OrgDTO } from './org';

export interface TreeElement {
  name: string;
  childs: TreeElement[];
  isFolder: boolean;

  clone(): TreeElement;
}
export type AttachmentType = "App\\Org" | "App\\Employee";

export interface AttachmentFilters {
  search?: string;
  types?: AttachmentType[];
  expirationStart?: Date;
  expirationEnd?: Date;
}

export interface AttachmentDTO extends CommonObjectDTO {
  type?: string;
  description?: string;
  filename?: string;
  url?: string;
  expiration?: Date;
  attachable?: OrgDTO | EmployeeDTO;
  attachable_type?: AttachmentType;
  folder?: FolderDTO;
  folder_id?: number;
}

export class Attachment extends CommonObject implements TreeElement {
  type?: string;
  description?: string;
  file?: File;
  filename?: string;
  expiration?: Date;
  org?: Org;
  employee?: Employee;
  attachableType: AttachmentType;
  folder?: Folder;
  folderId?: number;
  private _url?: string;

  constructor(dto: AttachmentDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.type = dto.type;
      this.description = dto.description;
      this.filename = dto.filename;
      this._url = dto.url;
      if (dto.expiration) {
        this.expiration = new Date(dto.expiration);
      }
      this.attachableType = dto.attachable_type;
      if (dto.attachable) {
        if (this.attachableType == "App\\Org") {
          this.org = new Org(dto.attachable as OrgDTO);
        }
        if (this.attachableType == "App\\Employee") {
          this.employee = new Employee(dto.attachable as EmployeeDTO);
        }
        this.addLoadedRelation("attachable");
      }
      this.folderId = dto.folder_id;
      if (dto.folder) {
        this.folder = new Folder(dto.folder as FolderDTO);
        this.folderId = this.folder.objectId;
        this.addLoadedRelation("folder");
      }
    }
  }

  get name(): string {
    return this.filename;
  }
  get childs(): TreeElement[] {
    return [];
  }

  get isFolder(): boolean {
    return false;
  }

  public toDTO(): AttachmentDTO {
    let result: AttachmentDTO = <AttachmentDTO>super.toDTO();
    if (this.org) {
      result.attachable = this.org.toDTO();
      result.attachable_type = "App\\Org";
    } else if (this.employee) {
      result.attachable = this.employee.toDTO();
      result.attachable_type = "App\\Employee";
    }
    Object.assign(result, {
      type: this.type,
      description: this.description,
      filename: this.filename,
      expiration: formatDateForBackend(this.expiration),
      folder_id: this.folderId,
      //folder: this.folder ? this.folder.toDTO() : null
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: Attachment
  ): Attachment {
    const formModel = formGroup.value;

    let attachment: Attachment = new Attachment(null);

    attachment.objectId = formModel.objectId;
    attachment.type = formModel.type;
    attachment.description = formModel.description;
    attachment.file = formModel.file;
    attachment.expiration = formModel.expiration
      ? new Date(formModel.expiration)
      : null;

    if (original) {
      attachment.objectId = original.objectId;
      attachment.loadedRelations = original.loadedRelations;
      attachment.folder = original.folder;
      attachment.folderId = original.folderId;
    }
    return attachment;
  }

  get url(): string {
    return this._url
      ? environment["laravel"]["serverUrl"] + "/" + this._url
      : null;
  }

  set url(url: string) {
    this._url = url;
  }

  clone(): Attachment {
    let cloned = new Attachment(null);
    return Object.assign(cloned, this);
  }

  get expired(): boolean {
    return !!this.expiration && new Date(this.expiration) < new Date();
  }
}

export interface FolderDTO extends CommonObjectDTO {
  name: string;
  description?: string;
  parent?: FolderDTO;
  parent_id?: number;
  sub_folders?: FolderDTO[];
  attachments?: AttachmentDTO[];
  org_id: number;
}

export class Folder extends CommonObject implements TreeElement {
  name: string;
  description?: string;
  parent?: Folder;
  parentId?: number;
  subFolders?: Folder[];
  attachments?: Attachment[];
  orgId: number;
  constructor(dto: FolderDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.name = dto.name;
      this.description = dto.description;
      this.parentId = dto.parent_id;
      if (dto.parent) {
        this.parent = new Folder(dto.parent);
        this.parentId = this.parent.objectId;
        this.addLoadedRelation("parent");
      }
      if (!!dto.sub_folders && dto.sub_folders.length) {
        this.subFolders = dto.sub_folders
          ? dto.sub_folders.map(child => {
            const result = new Folder(child);
            result.parent = this;
            return result;
          })
          : [];
        this.addLoadedRelation("sub_folders");
      }
      if (!!dto.attachments && dto.attachments.length) {
        this.attachments = dto.attachments
          ? dto.attachments.map(attachment => {
            const result = new Attachment(attachment);
            result.folder = this;
            return result;
          })
          : [];
        this.addLoadedRelation("attachments");
      }
      this.orgId = dto.org_id;
    }
  }

  get childs(): TreeElement[] {
    return [...(this.subFolders || []), ...(this.attachments || [])];
  }

  set childs(childs: TreeElement[]) {
    if (!childs) {
      this.subFolders = [];
      this.attachments = [];
      return;
    }
    this.subFolders = childs.filter(child => child.isFolder).map(child => child as Folder);
    this.attachments = childs.filter(child => !child.isFolder).map(child => child as Attachment);
  }

  get isFolder() {
    return true;
  }

  public toDTO(): FolderDTO {
    let result: FolderDTO = <FolderDTO>super.toDTO();
    Object.assign(result, {
      name: this.name,
      description: this.description,
      org_id: this.orgId,
      //parent: this.parent ? this.parent.toDTO() : null,
      parent_id: this.parent ? this.parent.objectId : this.parentId,
      sub_folders: !!this.subFolders ? this.subFolders.map(child => child.toDTO()) : [],
      attachments: !!this.attachments ? this.attachments.map(attachment => attachment.toDTO()) : []
    });
    return result;
  }

  clone(): Folder {
    let cloned = new Folder(null);
    return Object.assign(cloned, this);
  }

  sortSubfolders(ascending: boolean = true) {
    if (this.subFolders) {
      this.subFolders.forEach(folder => folder.sortSubfolders(ascending));
      this.subFolders = sortArray<Folder>(this.subFolders, "name", true, ascending);
      this.attachments = sortArray<Attachment>(this.attachments, "filename", true, ascending);
    }
    return this.subFolders;
  }

  get expirations(): Attachment[] {
    if (this.attachments) {
      return this.attachments.filter(a => !!a.expired).concat(this.subFolders ? this.subFolders.map(folder => folder.expirations).reduce((expirations, results) => {
        return results.concat(...expirations);
      }, []) : [])
    }
    return [];
  }
}
