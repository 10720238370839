import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: "prompt-dialog",
  templateUrl: "./prompt-dialog.component.html",
  styleUrls: ["./prompt-dialog.component.scss"]
})
export class PromptDialogComponent {
  constructor(public dialogRef: MatDialogRef<PromptDialogComponent>) { }

  public promptMessage: string;
  public title: string;
  public placeholder: string;
  public inputLabel: string;

  inputCtrl: FormControl = new FormControl("");

  set value(value: string) {
    this.inputCtrl.setValue(value);
  }

  get value(): string {
    return this.inputCtrl.value;
  }

  set required(required: boolean) {
    this.inputCtrl.clearValidators();
    if (required) {
      this.inputCtrl.setValidators([Validators.required]);
    }
    this.inputCtrl.updateValueAndValidity();
  }
}
