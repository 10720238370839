import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { getCurrentUser, State } from 'app/_reducers';
import { UserService } from 'app/_services/user.service';

import { Participant } from './../../_models/planning';
import { User } from './../../_models/user';
import { AlertService } from './../../_services/alert.service';
import { MailService } from './../../_services/mail.service';


@Component({
  selector: "app-send-mail-dialog",
  templateUrl: "./send-dialog.component.html",
  styleUrls: ["./send-dialog.component.scss"]
})
export class SendDialogComponent implements OnInit {
  recipients: Participant[];

  mailForm: FormGroup;

  currentUser: User;

  hidePlaceholders: boolean;
  hideIncludeCertificate: boolean;

  constructor(
    private fb: FormBuilder,
    private store: Store<State>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SendDialogComponent>,
    public userService: UserService,
    public alertService: AlertService,
    public mailService: MailService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store.select(getCurrentUser).subscribe(currentUser => {
      this.currentUser = currentUser;
    })
    this.createForm();
    if (this.data) {
      this.hidePlaceholders = !!this.data.hidePlaceholders;
      this.hideIncludeCertificate = !!this.data.hideIncludeCertificate;
    }
  }

  noMailRecipients(): Participant[] {
    return this.recipients ? this.recipients.filter(e => !e.employee.email) : [];
  }

  noCertificateRecipients(): Participant[] {
    return this.recipients ? this.recipients.filter(p => {
      return !(p.hasCertificate || p.completed);
    }) : [];
  }

  ngOnInit() {
    this.recipients = this.data.recipients;
    this.initForm();
  }

  createForm() {
    let group = {
      sender: ["", [Validators.required, Validators.email]],
      subject: ["", Validators.required],
      template: ["", Validators.required],
      senderInBcc: "",
      includeCertificates: ""
    };

    this.mailForm = this.fb.group(group);
  }

  initForm() {
    this.mailForm.patchValue({
      sender: this.currentUser.email,
      senderInBcc: true,
      subject: this.data.subject ? this.data.subject : `Comunicazione in merito al corso`,
      template: this.data.template ? this.data.template : `Gentilissimo referente aziendale,<br>
      la presente comunicazione riguarda il partecipante iscritto al corso in oggetto $PERSON_NAME$ $PERSON_SURNAME$<br>
      Comunichiamo che<br><br>
      Cordiali saluti<br>`
    });
  }

  close() {
    this.dialogRef.close();
  }

  send() {
    const formModel = this.mailForm.value;
    this.mailService.sendMailToParticipants(this.recipients, formModel.template,
      formModel.subject,
      formModel.sender,
      formModel.senderInBcc,
      formModel.includeCertificates
    ).subscribe(() => {
      this.alertService.showConfirmMessage(`E-mail inviata correttamente a ${this.recipients.length - this.noMailRecipients().length} persone`);
      this.close();
    }, error => {
      this.alertService.showErrorMessage(`Errore nell'invio e-mail`, error);
    })
  }
}
