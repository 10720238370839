import { FormControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

import { CommonObject } from './../_models/common-object';

const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
const BACKEND_DATE_FORMAT_WITH_TIME = "YYYY-MM-DD HH:mm:ss";

export function updateArray(
  oldArray: Array<CommonObject>,
  newArray: Array<CommonObject>,
  addIfNotExists?: boolean
) {
  if (!oldArray) {
    oldArray = newArray;
  } else {
    if (newArray) {
      newArray.forEach(newObject => {
        let itemIndex = oldArray.findIndex(
          item => item.objectId == newObject.objectId
        );
        if (itemIndex > -1) {
          oldArray[itemIndex] = newObject;
        } else if (addIfNotExists) {
          oldArray.push(newObject);
        }
      });
    }
  }
  return oldArray;
}

export function getFileType(fileName) {
  if (!fileName) return "";
  let extension = fileName
    .split(".")
    .pop()
    .toLowerCase();
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "bmp":
    case "gif":
    case "tiff":
      return "image";
    case "mp4":
      return "video";
    case "wav":
    case "mp3":
      return "audio";
    default:
      return "file";
  }
}

export function formatDateForBackend(date: Date, includeTime: boolean = false) {
  if (date) {
    return moment(date).format(
      includeTime ? BACKEND_DATE_FORMAT_WITH_TIME : BACKEND_DATE_FORMAT
    );
  }
  return null;
}

export function autocompleteSelectionValidator(
  control: FormControl
): ValidationErrors | null {
  let selection = control.value;
  if (typeof selection === "string") {
    return { invalid: true };
  }
  return null;
}

export function getMinutesBetweenTimes(
  startTime: string,
  endTime: string
): number {
  if (startTime && endTime) {
    let startSplits = startTime.split(":");
    let endSplits = endTime.split(":");
    let start = moment();
    let end = moment();
    start.set({
      hours: Number(startSplits[0]),
      minutes: Number(startSplits[1])
    });

    end.set({
      hours: Number(endSplits[0]),
      minutes: Number(endSplits[1])
    });
    return end.diff(start, "minutes");
  }
  return 0;
}

export function addHours(startTime: string, hours: number): string {
  if (startTime && hours > 0) {
    let startSplits = startTime.split(":");
    let start = moment();
    start.set({
      hours: Number(startSplits[0]),
      minutes: Number(startSplits[1])
    });
    return start
      .add(hours, "hours")
      .format("HH:mm")
      .toString();
  }
  return "";
}

export function sortArray<T>(array: Array<T>, key: string, ignoreCase: boolean = false, ascending: boolean = true): Array<T> {
  return array.sort((a, b) => {
    let aValue = `${a[key]}`;
    let bValue = `${b[key]}`;
    if (ignoreCase) {
      aValue = !!aValue ? aValue.toLowerCase() : aValue;
      bValue = !!bValue ? bValue.toLowerCase() : bValue;
    }
    if (aValue < bValue) {
      return ascending ? -1 : 1;
    }
    if (aValue > bValue) {
      return ascending ? 1 : -1;
    }
    return 0;
  });
}

