import { Injectable } from '@angular/core';
import { Attachment, AttachmentFilters } from 'app/_models/attachment';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ListResult } from './../_helpers/listResult.interface';
import { Deadline, DeadlineFilter, Employee } from './../_models/employee';
import { Order, Org } from './../_models/org';
import { Service } from './../_models/service';
import { LaravelDeadlineService } from './laravel/laravel-deadline.service';

@Injectable({ providedIn: "root" })
export class DeadlineService {
  constructor(private laravelDeadlineService: LaravelDeadlineService) { }

  public addDeadline(deadline: Deadline): Observable<Deadline> {
    return this.laravelDeadlineService.createDeadline(deadline.toDTO()).pipe(
      map(dto => {
        return new Deadline(dto);
      })
    );
  }

  public updateDeadline(deadline: Deadline): Observable<Deadline> {
    return this.laravelDeadlineService
      .updateDeadline(deadline.objectId, deadline.toDTO())
      .pipe(
        map(dto => {
          return new Deadline(dto);
        })
      );
  }

  public archiveDeadline(deadline: Deadline): Observable<Deadline> {
    return this.laravelDeadlineService.deleteDeadline(deadline.objectId).pipe(
      map(() => {
        return deadline;
      })
    );
  }

  public getDeadline(
    deadlineId: number,
    include?: string | string[]
  ): Observable<Deadline> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelDeadlineService
      .getDeadlineById(deadlineId, includes)
      .pipe(map(dto => new Deadline(dto)));
  }

  public getDeadlines(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filter?: DeadlineFilter,
    include?: string | string[]
  ): Observable<ListResult<Deadline>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelDeadlineService
      .getDeadlines(page, perPage, order, direction, filter, includes)
      .pipe(
        map(response => {
          return {
            data: response.data.map(dto => new Deadline(dto)),
            total: response.total
          };
        })
      );
  }

  public batchUpdateDeadlines(
    deadlines: Deadline[],
    executionDate: Date,
    expirationDate: Date,
    remindDate: Date
  ): Observable<Deadline[]> {
    return this.laravelDeadlineService
      .batchUpdateDeadlines(deadlines.map(deadline => deadline.objectId), {
        executionDate: executionDate,
        expirationDate: expirationDate,
        remindDate: remindDate
      })
      .pipe(map(dtos => dtos.map(dto => new Deadline(dto))));
  }

  public getOrgsWithDeadlines(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filter?: DeadlineFilter,
    include?: string | string[]
  ): Observable<ListResult<Org>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelDeadlineService
      .getOrgsWithDeadlines(page, perPage, order, direction, filter, includes)
      .pipe(
        map(response => {
          return {
            data: response.data.map(dto => new Org(dto)),
            total: response.total
          };
        })
      );
  }

  public getEmployeesWithDeadlines(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filter?: DeadlineFilter,
    include?: string | string[]
  ): Observable<ListResult<Employee>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelDeadlineService
      .getEmployeesWithDeadline(
        page,
        perPage,
        order,
        direction,
        filter,
        includes
      )
      .pipe(
        map(response => {
          return {
            data: response.data.map(dto => new Employee(dto)),
            total: response.total
          };
        })
      );
  }

  public createFilter(
    employee: Employee,
    service: Service,
    org: Org,
    order: Order,
    reminderStart: Date,
    reminderEnd: Date,
    expirationStart: Date,
    expirationEnd: Date,
    onlyPrivate: boolean,
    employeeQueryString: string,
    orderQueryString: string,
    includeArchived: boolean = false
  ): DeadlineFilter {
    let filter: DeadlineFilter = {
      employeeId: employee ? employee.objectId : null,
      serviceId: service ? service.objectId : null,
      orgId: org ? org.objectId : null,
      orderId: order ? order.objectId : null,
      reminderStart: reminderStart,
      reminderEnd: reminderEnd,
      expirationStart: expirationStart,
      expirationEnd: expirationEnd,
      onlyPrivate: onlyPrivate,
      employeeQueryString: employeeQueryString,
      orderQueryString: orderQueryString,
      includeArchived: includeArchived
    };
    return filter;
  }

  public sendReminderToOrgs(
    orgs: Org[],
    template: string,
    subject: string,
    sender: string,
    senderInBcc: boolean,
    expirationStart: Date,
    expirationEnd: Date,
    serviceId: number
  ): Observable<boolean> {
    return this.laravelDeadlineService
      .sendReminderToOrgs(
        orgs.map(org => org.objectId),
        template,
        subject,
        sender,
        senderInBcc,
        expirationStart,
        expirationEnd,
        serviceId
      );
  }

  public sendReminderToEmployees(
    employees: Employee[],
    template: string,
    subject: string,
    sender: string,
    senderInBcc: boolean,
    expirationStart: Date,
    expirationEnd: Date,
    serviceId: number
  ): Observable<boolean> {
    return this.laravelDeadlineService
      .sendReminderToEmployees(
        employees.map(employee => employee.objectId),
        template,
        subject,
        sender,
        senderInBcc,
        expirationStart,
        expirationEnd,
        serviceId
      );
  }

  public checkExistingOrderForEmployee(
    orderId: number,
    employeeId: number
  ): Observable<boolean> {
    return this.laravelDeadlineService.checkExistingDeadlineForEmployee(
      orderId,
      employeeId
    );
  }

  public exportDeadlines(
    filter?: DeadlineFilter
  ): Observable<Blob> {
    return this.laravelDeadlineService.exportReminders(filter)
      .pipe(map(data => new Blob([data], { type: "text/csv" })));
  }

  public importFromELearning(rows: string[]): Promise<any> {
    return this.laravelDeadlineService.importFromELearning(rows)
      .pipe(
        tap(data => {
          const blobContent = data.join('\n');

          const blob = new Blob([blobContent], { type: 'text/plain' });

          const now = moment().format('YYYYMMDD_HHmm');
          const fileName = `importazione_elearning_${now}.csv`;
          saveAs(blob, fileName)
        })
      ).toPromise();
    // return this.laravelDeadlineService.importFromELearning(rows).toPromise();
  }

  public getAttachments(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filters?: AttachmentFilters,
    include?: string | string[]
  ): Observable<ListResult<Attachment>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelDeadlineService
      .getAttachments(page, perPage, order, direction, filters, includes)
      .pipe(
        map(response => {
          return {
            data: response.data.map(dto => new Attachment(dto)),
            total: response.total
          };
        })
      );
  }

}
