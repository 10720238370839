import { Injectable } from '@angular/core';
import { Attachment, Folder } from 'app/_models/attachment';
import { Employee } from 'app/_models/employee';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResult } from '../_helpers/listResult.interface';
import { Org, OrgsFilter } from './../_models/org';
import { LaravelOrgService } from './laravel/laravel-org.service';

@Injectable({ providedIn: "root" })
export class OrgService {
  constructor(private laravelOrgService: LaravelOrgService) { }

  public addOrg(org: Org): Observable<Org> {
    return this.laravelOrgService.createOrg(org.toDTO(), org.logoFile, org.attachments.map(a => a.file)
    ).pipe(
      map(dto => {
        return new Org(dto);
      })
    );
  }

  public updateOrg(org: Org): Observable<Org> {
    return this.laravelOrgService
      .updateOrg(org.objectId, org.toDTO(), org.logoFile, org.isRelationLoaded("attachments") ? org.attachments.map(a => a.file) : null)
      .pipe(
        map(dto => {
          return new Org(dto);
        })
      );
  }

  public archiveOrg(org: Org): Observable<Org> {
    return this.laravelOrgService.deleteOrg(org.objectId).pipe(
      map(() => {
        return org;
      })
    );
  }

  public restoreOrg(org: Org): Observable<Org> {
    return this.laravelOrgService.restoreOrg(org.objectId).pipe(
      map(restored => {
        return new Org(restored);
      })
    );
  }

  public getOrg(orgId: number, include?: string | string[]): Observable<Org> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelOrgService
      .getOrgById(orgId, includes)
      .pipe(map(dto => new Org(dto)));
  }

  public getOrgs(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filter?: OrgsFilter,
    include?: string | string[]
  ): Observable<ListResult<Org>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelOrgService
      .getOrgs(
        page,
        perPage,
        order,
        direction,
        filter.onlyPartners,
        filter.includeArchived,
        filter.queryString,
        includes
      )
      .pipe(
        map(response => {
          return {
            data: response.data.map(dto => new Org(dto)),
            total: response.total
          };
        })
      );
  }

  public importOrg(orgId: number, data: any): Observable<any> {
    return this.laravelOrgService.importOrg(orgId, data);
  }

  public isVATExisting(orgId: number, VAT: string): Observable<boolean> {
    return this.laravelOrgService.checkVATExisting(orgId, VAT);
  }

  public importEmployees(org: Org, csv: any): Observable<Employee[]> {
    return this.laravelOrgService
      .importEmployees(org.objectId, csv)
      .pipe(map(dtos => dtos.map(dto => new Employee(dto))));
  }

  public exportStatus(orgId: number): Observable<Blob> {
    return this.laravelOrgService
      .exportStatus(orgId)
      .pipe(map(data => new Blob([data], { type: "text/csv" })));
  }

  public exportStatusPartner(orgId: number): Observable<Blob> {
    return this.laravelOrgService
      .exportStatusPartner(orgId)
      .pipe(map(data => new Blob([data], { type: "text/csv" })));
  }

  public importOrgs(orgs: Org[]): Promise<any> {
    var promises = [];
    if (orgs) {
      promises = orgs.map(org => this.laravelOrgService.importOrg(org.objectId, org).toPromise());
    }
    return Promise.all(promises);
  }

  public orgFolders(orgId: number): Observable<Folder[]> {
    return this.laravelOrgService
      .folders(orgId)
      .pipe(map(dtos => dtos.map(dto => new Folder(dto))))
  }

  public addFolder(name: string, parentId: number, orgId: number): Observable<Folder> {
    return this.laravelOrgService.saveFolder({
      name: name,
      parent_id: parentId,
      org_id: orgId,
    }).pipe(map(dto => new Folder(dto)));
  }

  public renameFolder(folder: Folder, name: string): Observable<Folder> {
    return this.laravelOrgService
      .saveFolder({
        id: folder.objectId,
        name: name,
        parent_id: folder.parentId,
        org_id: folder.orgId,
      })
      .pipe(map(dto => new Folder(dto)));
  }

  public moveFolder(folder: Folder, parentId: number): Observable<Folder> {
    return this.laravelOrgService
      .saveFolder({
        id: folder.objectId,
        name: folder.name,
        parent_id: parentId,
        org_id: folder.orgId,
      })
      .pipe(map(dto => new Folder(dto)));
  }

  public deleteFolder(folder: Folder): Observable<Folder> {
    return this.laravelOrgService
      .deleteFolder(folder.objectId)
      .pipe(map(() => folder));
  }

  public storeOrgAttachment(attachment: Attachment, orgId: number): Observable<Attachment> {
    return this.laravelOrgService
      .saveOrgAttachment(attachment.toDTO(), orgId, attachment.file)
      .pipe(map(dto => new Attachment(dto)));
  }

  public moveAttachment(attachmentId: number, folderId: number): Observable<Attachment> {
    return this.laravelOrgService
      .moveAttachment(attachmentId, folderId)
      .pipe(map(dto => new Attachment(dto)));
  }

  public deleteAttachment(attachment: Attachment): Observable<Attachment> {
    return this.laravelOrgService
      .deleteAttachment(attachment.objectId)
      .pipe(map(() => attachment));
  }

  public downloadAttachment(attachment: Attachment) {
    if (!!attachment) {

      this.laravelOrgService
        .downloadFile(attachment.objectId)
        .pipe(map(data => new Blob([data]))).subscribe(data => {
          saveAs(data, attachment.filename);
        })
    }
  }
}
