import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DeadlineService } from 'app/_services/deadline.service';
import { PapaParseService } from 'ngx-papaparse';

@Component({
  selector: "app-import-dialog",
  templateUrl: "./import-dialog.component.html",
  styleUrls: ["./import-dialog.component.scss"]
})
export class ImportDialogComponent implements OnInit {
  file: File;
  hasUpdates: boolean = false;
  importing: boolean = false;
  importCompleted: boolean = false;
  errors;

  constructor(
    private papa: PapaParseService,
    private deadlineService: DeadlineService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ImportDialogComponent>,
  ) { }

  ngOnInit() { }

  fileChanged(event) {
    this.file = event.target.files[0];
  }

  startImport() {
    let fileReader = new FileReader();
    this.importing = true;
    this.importCompleted = false;
    this.errors = null;
    fileReader.onload = e => {
      let data = <string>fileReader.result;
      let options = {
        skipEmptyLines: true,
        header: true,
        complete: (results, file) => {
          console.log("Parsed: ", results, file);
          this.deadlineService
            .importFromELearning(results.data)
            .then(() => {
              console.log("Import completed");
              this.importing = false;
              this.hasUpdates = true;
              this.importCompleted = true;
              this.file = null;
            })
            .catch(error => {
              this.importing = false;
              this.hasUpdates = true;
              this.errors = error.message;
              this.importCompleted = true;
              console.error("Errors while importing", error);
            });
        }
      };

      this.papa.parse(data, options);
    };
    fileReader.readAsText(this.file);
  }

  close() {
    this.dialogRef.close(this.hasUpdates);
  }
}
