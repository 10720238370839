import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Participant } from './../_models/planning';
import { LaravelMailService } from './laravel/laravel-mail.service';

@Injectable({ providedIn: 'root' })
export class MailService {
    constructor(private laraverlMailService: LaravelMailService) { }

    sendMailToParticipants(participants: Participant[], template: string,
        subject: string,
        sender: string,
        senderInBcc: boolean = false,
        includeCertificates: boolean = false
    ): Observable<boolean> {
        return this.laraverlMailService.sendMail(sender, subject, template, participants.map(p => p.objectId), senderInBcc, includeCertificates);
    }
}